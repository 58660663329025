<template>
  <div class="page1">
    <Row class="listTop">
      <Col span="7">
        <div class="leftTop">
          <span class="title"><span class="title-6">会员统计</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="left1">
            <Row :gutter="30" type="flex" align="middle" justify="space-between">
              <Col span="11">
                <Row :gutter="10" type="flex" align="middle" justify="center">
                  <Col span="11">
                    <!-- <Icon type="ios-home" size="50" color="#2775b6" /> -->
                    <!--                              <Icon custom="i-icon i-icon-shop_fill" size="24" color="#2775b6"  style="width:75px;height:75px;" />-->
                    <Row type="flex" align="bottom" justify="start">
                      <Col>
                        <div style="color: #3de7c9">会员总数：</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="13">
                    <Row type="flex" align="bottom" justify="start">
                      <Col>
                        <CountTo
                          style="color: #3de7c9; font-size: 25px; font-weight: bold; float: right"
                          :startVal="startVal"
                          :endVal="memberVal"
                          :duration="duration"
                        />
                        <!-- <dv-digital-flop :config="config" style="width: 100px; height: 50px" /> -->
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span="13">
                <Row ::gutter="10" type="flex" align="middle" justify="center">
                  <Col span="12">
                    <!-- <Icon type="logo-freebsd-devil" size="50" color="#2775b6" /> -->
                    <!--   <Icon type="logo-freebsd-devil" />                           <Icon custom="i-icon i-icon-shop_fill" size="24" color="#2775b6"  style="width:75px;height:75px;" />-->
                    <Row type="flex" align="bottom" justify="start">
                      <Col>
                        <div style="color: #3de7c9">有效会员数量：</div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="12">
                    <Row type="flex" align="bottom" justify="start">
                      <Col>
                        <CountTo
                          style="color: #3de7c9; font-size: 25px; font-weight: bold; float: right"
                          :startVal="startVal"
                          :endVal="memberVal1"
                          :duration="duration"
                        />
                        <!-- <dv-digital-flop :config="config1" style="width: 100px; height: 50px" /> -->
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <!--                      <Row ::gutter="10" type="flex" align="middle" style="padding-top: 20px;" justify="space-between">
                        <Col span="12">
                          <Row ::gutter="50" type="flex" align="middle" justify="center">
                            <Col span="4">
                              <Icon type="ios-options" size="50" color="#2775b6"/>
                              &lt;!&ndash;                           <Icon custom="i-icon i-icon-shop_fill" size="24" color="#2775b6"  style="width:75px;height:75px;" />&ndash;&gt;
                            </Col>
                            <Col span="20">
                              <Row type="flex" align="bottom" justify="start">
                                <Col>
                                  <div style="color:#3de7c9;text-align: center;">特种设备数量</div>
                                </Col>
                              </Row>
                              <Row type="flex" align="bottom" justify="start">
                                <Col>
                                  <dv-digital-flop :config="config" style="width:100px;height:50px;" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col span="12">
                          <Row ::gutter="50" type="flex" align="middle" justify="center">
                            <Col span="4">
                              <Icon type="logo-twitter" size="50" color="#2775b6"/>
                              &lt;!&ndash;   <Icon type="logo-twitter" />                           <Icon custom="i-icon i-icon-shop_fill" size="24" color="#2775b6"  style="width:75px;height:75px;" />&ndash;&gt;
                            </Col>
                            <Col span="20">
                              <Row type="flex" align="bottom" justify="start">
                                <Col>
                                  <div style="color:#3de7c9">食用农产品数量</div>
                                </Col>
                              </Row>
                              <Row type="flex" align="bottom" justify="start">
                                <Col>
                                  <dv-digital-flop :config="config" style="width:100px;height:50px;" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>-->
            <!--                        <double-area :selectRangeDate="selectRangeDate" ref='chart1'></double-area>-->
          </div>
          <!-- <div class="left2">
            <web-bar ref="chart2" key="chart2" :data="data1"></web-bar>
            <div style="width: 40%; float: left; height: 100%">
              <pie ref="chart3" id="left_2_2" :data="data2"></pie>
            </div>
          </div> -->
          <div class="left3">
            <!-- <web-bar ref="chart4" key="chart4" :data="data3"></web-bar> -->
            <div style="width: 100%; float: left; height: 100%" class="box">
              <pie ref="chart5" id="left_3_2" :data="data4"></pie>
            </div>
          </div>
        </div>
      </Col>
      <Col span="10" style="padding: 10px 10px">
        <Row type="flex" align="top" justify="space-between">
          <Col span="6">
            <div class="border-box">
              <Row type="flex" align="middle" justify="center" class="border-bottom-right">
                <Col span="12" style="text-align: center">
                  <div style="color: #f5f5f5; font-weight: bold">包含景区</div>
                </Col>
                <Col span="12" style="text-align: center">
                  <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
                </Col>
              </Row>
              <!--                    <Row type="flex" align="middle" justify="space-between" class="border-bottom-right">
                      <Col span="12">
                        <div style="color: #f5f5f5;font-weight: bold;">上传上级数</div>
                      </Col>
                      <Col span="12">
                        <CountTo
                            style="color: #3de7c9; font-size: 25px;font-weight: bold;"
                            :startVal='startVal'
                            :endVal='endVal'
                            :duration='duration'
                        />
                      </Col>
                    </Row>-->
            </div>
          </Col>
          <Col span="12" style="top: -50px">
            <Echart :cdata="cdata1" />
          </Col>
          <Col span="6">
            <div class="border-box-right">
              <Row type="flex" align="middle" justify="center" class="border-bottom-left">
                <Col span="13" style="text-align: center">
                  <div style="color: #f5f5f5; font-weight: bold">开园景区</div>
                </Col>
                <Col span="11" style="text-align: center">
                  <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="upendVal" :duration="duration" />
                </Col>
              </Row>
              <!--                    <Row type="flex" align="middle" justify="space-between" class="border-bottom-left">
                      <Col span="12">
                        <div style="color: #f5f5f5;font-weight: bold;margin-left: 1rem;">上传市级数</div>
                      </Col>
                      <Col span="12">
                        <CountTo
                            style="color: #3de7c9; font-size: 25px;font-weight: bold;float: right;"
                            :startVal='startVal'
                            :endVal='endVal'
                            :duration='duration'
                        />
                      </Col>
                    </Row>-->
            </div>
          </Col>
        </Row>
        <!--                <radar-part ref="chart6"></radar-part>-->
      </Col>
      <Col span="7">
        <!--                <div class="rightTop-1">
                    <span class='title'><span class="title-8">追溯链条</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <world-map ref="chart7"></world-map>
                </div>-->
        <div class="rightTop-2">
          <span class="title"><span class="title-6">会员区域排名</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <!-- <div class="rightTop-list"> -->
          <!--                      <Row type="flex" align="middle" justify="center">
                                          <Col span="12">
                                            <Echart :cdata="cdata" :height="height"/>
                                          </Col>
                                        </Row>-->
          <dv-scroll-ranking-board :config="rankingConfig" style="width: 100%; height: 100%; padding: 1rem" />

          <!--                        <div class="list">
                                              <popular-bar ref="chart8"></popular-bar>
                                          </div>
                                          <div class="list">
                                              <ring ref="chart9" :data="data5"></ring>
                                          </div>
                                          <div class="list">
                                              <ring ref="chart10" :data="data6"></ring>
                                          </div>-->
          <!-- </div> -->
        </div>
        <div class="rightTop-1">
          <span class="title"><span class="title-6">入园量情况</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <!-- <div class="rightTop-list"> -->
          <!--                      <Row type="flex" align="middle" justify="center">
                                          <Col span="12">
                                            <Echart :cdata="cdata" :height="height"/>
                                          </Col>
                                        </Row>-->
          <!-- <dv-scroll-ranking-board :config="boardConfig2" style="width: 100%; height: 100%; padding: 1rem" /> -->
          <dv-scroll-board class="dv" :config="boardConfig2" style="width: 100%; height: 90%; padding: 0.6rem 0.6rem 0 0.6rem" />
          <!--                        <div class="list">
                                              <popular-bar ref="chart8"></popular-bar>
                                          </div>
                                          <div class="list">
                                              <ring ref="chart9" :data="data5"></ring>
                                          </div>
                                          <div class="list">
                                              <ring ref="chart10" :data="data6"></ring>
                                          </div>-->
          <!-- </div> -->
        </div>
      </Col>
    </Row>
    <Row ::gutter="40" type="flex" justify="space-between" align="middle" class="listBottom">
      <Col span="8">
        <div class="content">
          <span class="title"><span class="title-6">会员年龄段占比</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <Row class="topLine">
            <!--                        <Col span="24">
                            <lines ref="chart11"></lines>
                        </Col>-->
            <!--                      <Col span="12">
                        <lines ref="chart11"></lines>
                      </Col>-->
            <Col span="24">
              <pie-ring ref="chart13" :data="data8"></pie-ring>
            </Col>
          </Row>
          <!--                    <Row class="bottomPie">
                        <Col span="12">
                            <pie-ring ref="chart12" :data="data7"></pie-ring>
                        </Col>
                        <Col span="12">
                            <pie-ring ref="chart13" :data="data8"></pie-ring>
                        </Col>
                    </Row>-->
        </div>
      </Col>
      <Col span="8">
        <div class="content">
          <span class="title"><span class="title-6">新老会员对比</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="behavior">
            <!--                        <area-chart style="height: 55%" ref="chart14" :select-range-date="selectRangeDate"
                                    :config="configData9"></area-chart>-->
            <colors-bar ref="chart15" :data="colorsData" style="height: 100%"></colors-bar>
          </div>
        </div>
      </Col>
      <!--            <Col span='4'>
                <div class="content">
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <span class="title"><span class="title-6">企业备案情况</span></span>
                    <funnel ref="chart16"></funnel>
                </div>
            </Col>-->
      <Col span="8">
        <div class="content">
          <span class="title"><span class="title-6">景区全年入园量</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="appUse">
            <dv-capsule-chart :config="capsuleConfig" style="width: 80%; height: 90%; padding: 2rem 0 0 2rem" />
            <!--                      <colors-bar-ts ref="chart15" :data="colorsDataTs" style="height: 16rem;padding: 2rem;"></colors-bar-ts>-->
            <!--                       <line-bar ref="chart17"></line-bar>-->
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
/*const radarPart = () => import('./components/page1/radarPart.vue');*/
/*const doubleArea = () => import('./components/page1/LineMarker.vue');*/
import { getYktData, getAreaCount, getAreaNum, getAreaOrder, getAsyncHomeData, getBaseNum, getClassBusi, getnodeZb } from '@/api/home'
/*const funnel = () => import('./components/page1/funnel');*/
//const lineBar = () => import('./components/page1/lineBar')
/*const areaChart = () => import('./components/areaChart');*/
import CountTo from 'vue-count-to'

const Echart = () => import('./components/page1/chart.vue')
// const webBar = () => import('./components/page1/webBar.vue')
const pie = () => import('./components/pie')
/*const worldMap = () => import('./components/page1/worldMap');*/
//const ring = () => import('./components/page1/ring');
// const popularBar = () => import('./components/page1/popularBar')
//const lines = () => import('./components/page1/lines')
const pieRing = () => import('./components/page1/pieRing')
const colorsBar = () => import('./components/page1/colorsBar')
/*const colorsBarTs = () => import('./components/page1/colorsBarTs');*/

export default {
  name: 'page1',
  props: {
    selectRangeDate: Array
  },
  components: {
    CountTo,
    Echart, // 中心
    /* radarPart,// 中心*/
    /*    doubleArea, // 面积图*/
    // webBar, // 柱图
    pie, // 饼图
    /* worldMap, // 世界地图*/
    //      ring, // 圆环
    // popularBar, // 柱图
    //lines, //折线图
    pieRing, // 饼环图
    colorsBar // 柱图
    /*    colorsBarTs,*/
    /* funnel, // 漏斗图*/
    //lineBar //柱图
    /*areaChart // 面积图*/
  },
  data() {
    return {
      startVal: 0,
      endVal: 32,
      upendVal: 28,
      memberVal: 1512638,
      memberVal1: 356298,
      duration: 5000,
      height: '200px',
      cdata1: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '卢龙县',
          value: 2,
          upnum: 2,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '昌黎县',
          value: 2,
          upnum: 2
        },
        {
          name: '抚宁区',
          value: 7,
          upnum: 5
        },
        {
          name: '青龙满族自治县',
          value: 2,
          upnum: 2
        },
        {
          name: '北戴河区',
          value: 7,
          upnum: 5
        },
        {
          name: '海港区',
          value: 4,
          upnum: 4
        },
        {
          name: '山海关区',
          value: 10,
          upnum: 6
        }
      ],
      capsuleConfig: {
        data: [
          {
            name: '2024',
            value: 856215
          },
          {
            name: '2023',
            value: 695187
          },
          {
            name: '2022',
            value: 502316
          },
          {
            name: '2021',
            value: 102562
          },
          {
            name: '2020',
            value: 10253
          }
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        /* unit: '单位',*/
        showValue: true
      },
      // config: {
      //   number: [3000000],
      //   content: '{nt}人'
      // },
      // config1: {
      //   number: [350000],
      //   content: '{nt}会员'
      // },
      boardConfig2: {
        header: ['景区名称', '数量'],
        data: [
          // ['动物园', 3015],
          // ['新澳海底世界', 2230],
          // ['碧螺塔', 1865]
          // ['秦皇星际小蚂蚁乐园', 198],
          // ['紫金湾游乐场', 562]
          // ['天下第一关联票', 265],
          // ['南戴河娱乐中心', 120],
          // ['圣蓝海洋公园', 336],
          // ['御水湾温泉水上乐园', 243],
          // ['昌黎国际滑沙中心', 185],
          // ['冰糖峪', 98],
          // ['秦宇宙智慧乐园（园博园）', 132],
          // ['龙云谷', 89],
          // ['公主号邮船', 0],
          // ['长寿山', 26],
          // ['乱刀峪', 37],
          // ['傍水崖', 28],
          // ['求仙6号', 12],
          // ['背牛顶', 268],
          // ['浪淘沙游船', 33],
          // ['老龙头', 165],
          // ['秦皇宫', 152],
          // ['燕塞湖', 102],
          // ['左右山谷', 89],
          // ['鸽子窝', 352],
          // ['联峰山', 188],
          // ['长城华夏酒庄', 162],
          // ['花果山', 56],
          // ['孟姜女庙', 103],
          // ['花溪城水世界', 205],
          // ['清河源萌宠乐园', 78],
          // ['清河源高山峡谷漂流', 52]
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        indexHeader: '序号',
        columnWidth: [80, 280, 100],
        index: true,
        align: ['center']
      },
      rankingConfig: {
        data: [
          {
            name: '海港区',
            value: 207127
          },
          {
            name: '山海关区',
            value: 25724
          },
          {
            name: '北戴河区',
            value: 10706
          },
          {
            name: '卢龙县',
            value: 40089
          },
          {
            name: '昌黎县',
            value: 25723
          },
          {
            name: '抚宁区',
            value: 50111
          },
          {
            name: '青龙县',
            value: 13363
          },
          {
            value: 126949,
            name: '其它地区',
            itemStyle: {
              color: '#66659f'
            }
          }
        ]
      },
      cdata: [
        /* {
                // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
                name: '谯城区',
                value: 10,
                upnum: 8
              },
              {
                name: '涡阳县',
                value: 9,
                upnum: 8
              },
              {
                name: '蒙城县',
                value: 8,
                upnum: 8
              },
              {
                name: '利辛县',
                value: 7,
                upnum: 8
              }*/
      ],
      data1: {
        // 柱图数据1
        name: '活跃商户',
        number: '0',
        data: ['卢龙县', '昌黎县'],
        color: '192,35,42',
        value: []
      },
      data2: {
        // 饼图数据1
        title: '活跃商户分类占比',
        color: '#BE232A',
        data: [
          {
            value: 0,
            name: '食用农产品',
            itemStyle: {
              color: '#a262f2'
            }
          },
          {
            value: 0,
            name: '食品',
            itemStyle: {
              color: '#2ca8fe'
            }
          }
        ]
      },
      data3: {
        // 柱图数据2
        name: '节点建设数量',
        number: '100',
        data: ['抚宁区', '青龙县'],
        color: '40,112,232',
        value: []
      },
      // 饼图数据2
      data4: {
        title: '会员地区分布占比',
        color: '#2C7BFE',
        data: [
          {
            value: 207127,
            name: '海港区',
            itemStyle: {
              color: '#feed2c'
            }
          },
          {
            value: 25724,
            name: '山海关区',
            itemStyle: {
              color: '#2ca8fe'
            }
          },
          {
            value: 10706,
            name: '北戴河区',
            itemStyle: {
              color: '#feac2c'
            }
          },
          {
            value: 13363,
            name: '青龙县',
            itemStyle: {
              color: '#c2232a'
            }
          },
          {
            value: 25723,
            name: '昌黎县',
            itemStyle: {
              color: '#252448'
            }
          },
          {
            value: 50111,
            name: '抚宁区',
            itemStyle: {
              color: '#32a297'
            }
          },
          {
            value: 40089,
            name: '卢龙县',
            itemStyle: {
              color: '#04692d'
            }
          },
          {
            value: 126949,
            name: '其它地区',
            itemStyle: {
              color: '#66659f'
            }
          }
        ]
      },
      // 环形图数据
      /* data5: {
                title: '环形图数据1',
                data: [
                    {
                        value: 335,
                        name: '模拟1',
                        itemStyle: {
                            color: '#252448'
                        }
                    },
                    {
                        value: 310,
                        name: '模拟2',
                        itemStyle: {
                            color: '#2ca8fe'
                        }
                    },
                    {
                        value: 234,
                        name: '模拟3',
                        itemStyle: {
                            color: '#feed2c'
                        }
                    },
                    {
                        value: 135,
                        name: '其他',
                        itemStyle: {
                            color: '#2871ea'
                        }
                    },
                    {
                        value: 200,
                        name: '模拟4',
                        itemStyle: {
                            color: '#fe672c'
                        }
                    }
                ]
            },*/
      // 环形数据2
      /*   data6: {
                title: '热词传播次数',
                data: [
                    {
                        value: 335,
                        name: '模拟1',
                        itemStyle: {
                            color: '#69f262'
                        }
                    },
                    {
                        value: 310,
                        name: '模拟2',
                        itemStyle: {
                            color: '#c0232a'
                        }
                    },
                    {
                        value: 234,
                        name: '模拟3',
                        itemStyle: {
                            color: '#2cfcfe'
                        }
                    },
                    {
                        value: 135,
                        name: '其他',
                        itemStyle: {
                            color: '#252448'
                        }
                    },
                    {
                        value: 200,
                        name: '模拟4',
                        itemStyle: {
                            color: '#a262f2'
                        }
                    }
                ]
            },
            data7: {
                title: '收支笔数及占比',
                data: [
                    {
                        value: 70,
                        name: '收入',
                        itemStyle: {
                            color: '#c0232a'
                        }
                    },
                    {
                        value: 60,
                        name: '支出',
                        itemStyle: {
                            color: '#2870e8'
                        }
                    },
                ],
                data1: [
                    {
                        value: 40,
                        name: '红包',
                        itemStyle: {
                            color: '#c0232a'
                        }
                    },
                    {
                        value: 60,
                        name: '转账',
                        itemStyle: {
                            color: '#2870e8'
                        }
                    },
                ]
            },*/
      data8: {
        title: '会员年龄占比图',
        /* data: [
                    {
                        value: 80,
                        name: '蔬菜类',
                        itemStyle: {
                            color: '#c0232a'
                        }
                    },
                    {
                        value: 60,
                        name: '水果类',
                        itemStyle: {
                            color: '#2870e8'
                        }
                    },
                ],*/
        data1: [
          {
            value: 29732,
            name: '0~15岁',
            itemStyle: {
              color: '#c2232a'
            }
          },
          {
            value: 103563,
            name: '15~35岁',
            itemStyle: {
              color: '#fe672c'
            }
          },
          {
            value: 133630,
            name: '35~55岁',
            itemStyle: {
              color: '#a262f2'
            }
          },
          {
            value: 66815,
            name: '55岁以上',
            itemStyle: {
              color: '#2870e8'
            }
          }
        ]
      },
      /*  // 交友分析面积图
            configData9: {
                title: '【交友分析】',
                color: '#75deef',
                name: ['（人）', '（人）'],
                data: [
                    {
                        name: '新增好友数',
                        color: ['#feed2c', '#353103'],
                        data: [240, 132, 101, 134, 90, 170, 110]
                    },
                    {
                        name: '好友总数',
                        color: ['#2871ea', '#0a1b41'],
                        data: [20, 102, 101, 134, 190, 150, 120]
                    },
                    {
                        name: '新增群数',
                        color: ['#935adf', '#230f3e'],
                        data: [100, 32, 101, 134, 150, 110, 180]
                    },
                    {
                        name: '群总数',
                        color: ['#e65f2d', '#551f0b'],
                        data: [120, 122, 141, 144, 60, 220, 120]
                    }
                ]
            },*/
      // 交友方式柱图
      colorsData: [
        {
          itemStyle: {
            color: '#87fa23'
          },
          name: '新增会员',
          value: 100000
        },

        {
          itemStyle: {
            color: '#2c7bfe'
          },
          name: '会员续费',
          value: 200000
        }
      ],
      colorsDataTs: [
        {
          itemStyle: {
            color: '#2c7bfe'
          },
          name: '电梯',
          value: 0
        },
        {
          itemStyle: {
            color: '#c2232a'
          },
          name: '气瓶',
          value: 0
        }
      ],
      resizeFn: null,
      timerData: null
    }
  },
  methods: {
    getYktCurrentDayData() {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const currentDate = `${year}-${month}-${day}`
      const data = {
        limit: 100,
        offset: 1,
        btmzcrq: currentDate,
        btmyxrq: currentDate
      }
      getYktData(data).then((res) => {
        // console.log(res)

        this.boardConfig2.data = res.data.map((item) => {
          return [item.bteusername, item.inserttime]
        })
        //检测到数据更新重新赋值config 没有这步 dv-scroll-board标签会加载页面没有数据
        this.boardConfig2 = { ...this.boardConfig2 }
        // this.boardConfig2.data.push([bteusername, inserttime])
      })
    },
    getAreaCount() {
      getAreaCount().then((res) => {
        const arr = res.data.filter((item) => item.type === '00')
        const arrs = res.data.filter((item) => item.type === '01')
        this.data1.value = arr.map((item) => item.num)
        this.data3.value = arrs.map((item) => item.num)
        for (let i of arrs) {
          this.data4.data = this.data4.data.map((item) => {
            if (item.name === i.name) {
              item.value = i.num
            }
            return item
          })
        }
      })
    },
    getAsyncHomeData() {
      getAsyncHomeData({
        startTime: this.startTime,
        endTime: this.endTime
      }).then((res) => {
        const { node, busi } = res.data
        const newVal = { number: [Number(node)], content: '{nt}家' }
        const newBusi = { number: [Number(busi)], content: '{nt}家' }
        this.config = { ...newVal }
        this.config1 = { ...newBusi }
      })
    },
    getAreaOrder() {
      getAreaOrder().then((res) => {
        this.rankingConfig.data = res.data.map((item) => {
          return Object.assign({}, { name: item.B_TE_AREA_NAME, value: item.num })
        })
        this.rankingConfig = { ...this.rankingConfig }
      })
    },
    async getBaseNum() {
      getBaseNum().then((res) => {
        //食用农产品
        const syncp = res.data.filter((item) => item.CLASSTYPE === '1')
        const sp = res.data.filter((item) => item.CLASSTYPE === '2')
        //药品
        const yp = res.data.filter((item) => item.CLASSTYPE === '3')
        //特种设备
        const tzsb = res.data.filter((item) => item.CLASSTYPE === '5')
        this.capsuleConfig.data = tzsb.map((item) => {
          return Object.assign({}, { name: item.AREANAME, value: item.num })
        })
        this.capsuleConfig = { ...this.capsuleConfig }
        this.boardConfig2.data = syncp.map((item) => {
          return Object.assign([], [item.AREANAME, item.num])
        })
        this.boardConfig2 = { ...this.boardConfig2 }
        this.rankingConfig.data = syncp.map((item) => {
          return Object.assign({}, { name: item.AREANAME, value: item.num })
        })
        this.rankingConfig = { ...this.rankingConfig }
        const colorArr = ['#c2232a', '#fe672c', '#a262f2', '#2870e8']
        this.data8.data1 = sp.map((item, index) => {
          return Object.assign(
            {},
            {
              name: item.AREANAME,
              value: item.num,
              itemStyle: { color: colorArr[index] }
            }
          )
        })
        const colorArr2 = ['#2c7bfe', '#c2232a', '#feed2c', '#a262f2']

        this.colorsData = yp.map((item, index) => {
          return Object.assign(
            {},
            {
              name: item.AREANAME,
              value: item.num,
              itemStyle: { color: colorArr2[index] }
            }
          )
        })
        console.log('colorsData::', this.colorsData)
      })
    },
    getAreaNum() {
      getAreaNum().then((res) => {
        const ll = res.data.find((item) => item.AREA === 'LL')
        const oo = res.data.filter((item) => item.AREA === '00')
        this.cdata = oo.map((item) => {
          return Object.assign({}, { name: item.AREA_NAME, value: item.innum, upnum: item.upnum })
        })
        this.endVal = ll.innum
        this.upendVal = ll.upnum
      })
    },
    getnodeZb() {
      getnodeZb().then((res) => {
        for (let i of res.data) {
          this.data4.data = this.data4.data.map((item, index) => {
            const colorArr = ['#a262f2', '#2ca8fe', '#feac2c', '#c0232a', '#252448']
            if (item.name === i.classtype) {
              item.name = i.classtype
              item.value = i.mm
              item.itemStyle = { color: colorArr[index] }
            }
            return item
          })
        }
        console.log(this.data4.data)
      })
    },
    getClassBusi() {
      getClassBusi().then((res) => {
        for (let i of res.data) {
          const colorArr = ['#a262f2', '#2ca8fe']
          this.data2.data = this.data2.data.map((item, index) => {
            if (item.name === i.classtype) {
              item.name = i.classtype
              item.value = i.mm
              item.itemStyle = { color: colorArr[index] }
            }
            return item
          })
        }
        console.log(this.data2.data)
      })
    }
  },
  watch: {
    selectRangeDate: function () {
      for (let i = 1; i < 18; i++) {
        this.$refs['chart' + i].setChart()
      }
    }
  },
  created() {
    // this.getAsyncHomeData()
    // this.getAreaCount()
    // this.getBaseNum()
    // this.getAreaNum()
    // this.getClassBusi()
    // this.getnodeZb()
    // this.getAreaOrder()
  },
  mounted() {
    //五分钟调用依次接口 获取最新入园量数据
    this.timerData = setInterval(() => {
      this.getYktCurrentDayData()
    }, 5 * 60 * 1000)
    this.getYktCurrentDayData()

    this.resizeFn = this.$debounce(() => {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      for (let i = 1; i < 18; i++) {
        this.$refs['chart' + i].setChart()
      }
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    clearInterval(this.timerData)
    this.timerData = null
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>
<style lang="less">
.page1 {
  height: 100%;
  width: 100%;
  padding: 14px 20px 20px;
  overflow: hidden;

  .listTop {
    height: 60%;
    .ivu-col {
      height: 100%;

      .leftTop {
        width: 100%;
        height: 100%;
        border: 1px solid #0d2451;
        position: relative;

        .left1 {
          width: 100%;
          height: 30%;
          padding-top: 10%;
          padding-left: 4%;
        }

        .left2,
        .left3 {
          display: flex;
          justify-content: center;
          // align-items: center;
          width: 100%;
          height: 80%;
          // height: 35%;
          padding: 30px 0;
        }
      }

      .rightTop-1 {
        // width: 100%;
        height: 55%;
        margin-top: 5%;
        border: 1px solid #0d2451;
        position: relative;
        // overflow: hidden;
        .title-6 {
        }
      }

      .rightTop-2 {
        width: 100%;
        height: 39%;
        border: 1px solid #0d2451;
        position: relative;
        .dv-scroll-ranking-board .ranking-column {
          border-bottom: 0;
          margin-top: 0;
        }
        .rightTop-list {
          width: 100%;
          height: 50%;
          padding-top: 3%;
          overflow: hidden;
          ::v-deep .dv {
            // height: 50%;
            overflow: hidden !important;
          }
          .list {
            width: 30%;
            height: 100%;
            float: left;

            &:first-child {
              width: 40%;
            }
          }
        }
      }
    }
    .border-box {
      .border-bottom-right {
        margin-top: 1rem;
        /* !*首先我们设置边框只显示底部，宽度为2px的实线。*!*/
        /* !*设置线性渐变*!*/

        background: url('../assets/images/border.png') no-repeat 0 100%;
        background-size: 120% auto;
        background-position: 100% 100%;
      }
    }
    .border-box-right {
      .border-bottom-left {
        position: relative;
        overflow: hidden;
        margin-top: 1rem;

        /*首先我们设置边框只显示底部，宽度为2px的实线。*/
        /*设置线性渐变*/
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0%;
          width: 120%;
          height: 90%;
          z-index: -1;
          background: url('../assets/images/border.png') no-repeat 0 100%;
          background-size: 100% auto;

          transform: rotateY(180deg);
        }
      }
    }
    /*.border-bottom-right:after{
        content: '';
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        border-color: linear-gradient(to right, #0396ff, #21f7e8);
        border-radius: 30px;
        z-index: -1;
      }*/
  }

  .listBottom {
    height: 40%;
    .ivu-col {
      height: 100%;
      .content {
        margin-top: 30px;
        height: calc(~'100% - 30px');
        border: 1px solid #0d2451;
        position: relative;

        .ivu-row {
          &.topLine {
            height: 65%;
            margin-top: 10%;
          }

          &.bottomPie {
            height: 45%;
          }

          .ivu-col {
            height: 100%;

            .charts-list {
              height: 100%;
              width: 100%;
            }
          }
        }

        .behavior {
          width: 100%;
          height: 100%;
        }

        .appUse {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
